const JSBI = require('jsbi');

export const toBI = (number) => {
    return JSBI.BigInt(number);
};

export const calculateShidoForMaxPurchase = (maxPerPurchase, rate) => {
    //const maxPurchaseBigInt = JSBI.BigInt(maxPerPurchase);
    //const rateBigInt = JSBI.BigInt(rate);

    // Adjust for decimals (if needed) and perform division
    const shidoNeededBigInt = maxPerPurchase / rate // JSBI.divide(maxPurchaseBigInt, rateBigInt);

    // Convert to float for display
    return convertBigIntToFloat(shidoNeededBigInt, 18); // Assuming 18 decimals
};

export const convertBigIntToFloat = (bigIntValue, decimals = 18) => {
    const SCALE = JSBI.exponentiate(toBI(10), toBI(decimals));
    return Number(bigIntValue.toString()) / Number(SCALE.toString());
};
