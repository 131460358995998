import React, { useState, useEffect, useCallback } from "react";
import Web3 from "web3";
import { ABI, TOKEN_ABI } from "./abi.js";
import formatCooldown from "../funcs/timeParse.js"
import { toBI, calculateShidoForMaxPurchase } from "../funcs/bigint.js"
import ProgressBar from "../widgets/progressBar.js"
import "../css/presale.css"
import Navbar from "../widgets/NavBar"
import { createTokenWidget } from "../funcs/tokenwidget.js"
import { trackEvent } from '../funcs/analytics.js';
import { useLocation } from 'react-router-dom';

// const CONTRACT_ADDRESS1 = "0xfb50989ccd76399Db7a84CFBC577C4a831347BCB";
// const CONTRACT_ADDRESS = "0xA7E6a9fA8847Fbad72477e63704008AFF8E3e385";
// const CONTRACT_ADDRESS = "0xC9898Ae6FDbDDC63330d16D3AD8880A2FD6C2B93";
const CONTRACT_ADDRESS = "0xdD75c1a25C3bc4874C00f33C8639316dc819F34c"
const TOKEN_CONTRACT_ADDRESS = "0x2835Ad9a421C14E1C571a5Bb492B86b7E8f5873A"

function PreSale() {
  const [account, setAccount] = useState(null);
  const [contract, setContract] = useState(null);
  const [rate, setRate] = useState(0);
  const [cap, setCap] = useState(0);
  const [tokensSold, setTokensSold] = useState(0);
  const [deadline, setDeadline] = useState(0);
  const [start, setStart] = useState(0);
  const [cooldown, setCooldown] = useState(0);
  const [lastPurchase, setLastPurchase] = useState(0);
  const [purchased, setPurchased] = useState(0);
  const [userCooldown, setUserCooldown] = useState(0);
  const [maxPerPurchase, setMaxPerPurchase] = useState(0);
  const [maxPerAccount, setMaxPerAccount] = useState(0);
  const [amount, setAmount] = useState("");
  const JSBI = require('jsbi');
  const [amountInWei, setAmountInWei] = useState("");
  const [error, setError] = useState("");
  const [balance, setBalance] = useState({ amount: "", symbol: "SHIDO" });
  const [owner, setOwner] = useState(null);
  const [buyers, setBuyers] = useState("");
  const [kiddoSC, setKiddoSC] = useState("");
  const [kiddoHeld, setKiddoHeld] = useState("");
  const [maxShido, setMaxShido] = useState("");

  const location = useLocation();
  const checkRemainingTime = useCallback((currentTime, eventTimestamp, duration = 0) => {
    const currentTimeBigInt = toBI(String(currentTime));
    const eventTimeBigInt = toBI(String(eventTimestamp));
    const durationBigInt = toBI(String(duration));

    // Calculate elapsed time and remaining time
    const elapsedTime = JSBI.subtract(currentTimeBigInt, eventTimeBigInt);
    const remainingTime = JSBI.subtract(durationBigInt, elapsedTime);

    // Ensure the result is non-negative
    return JSBI.greaterThanOrEqual(remainingTime, toBI(0)) ? remainingTime : toBI(0);
  }, [JSBI]);

  const fetchOwner = useCallback(async () => {
    if (contract) {
      try {
        const ownerAddress = await contract.methods.owner().call();
        setOwner(ownerAddress.toLowerCase()); // Normalize to lowercase for comparison
      } catch (error) {
        console.error("Error fetching contract owner:", error);
      }
    }
  }, [contract]);

  const handleAmountChange = async (e) => {
    const input = e.target.value.replace(",", "."); // Allow float input
    if (Number(input) < 0) {
      setError("Invalid input. Please enter a valid number.");
      setAmount(input);
      setAmountInWei("");
      return;
    }

    try {
      const wei = Web3.utils.toWei(input || "0", "ether");
      if (contract) {
        const remainingCap = cap - tokensSold; // Remaining tokens available
        const cooldownRemaining = await checkCooldown();
        const outputToken = wei * rate;

        console.log(convertBigIntToFloat(outputToken), convertBigIntToFloat(maxPerPurchase));
        // Validation checks
        if (outputToken > remainingCap) {
          setError("Input exceeds the remaining cap for this round.");
        } else if (convertBigIntToFloat(outputToken) > convertBigIntToFloat(maxPerPurchase)) {
          setError("Input exceeds the max allowed per purchase.");
        } else if (convertBigIntToFloat(outputToken) + convertBigIntToFloat(purchased) > convertBigIntToFloat(maxPerAccount)) {
          setError("Input exceeds the max allowed per account.");
        } else if (cooldownRemaining > 0) {
          setError(`Please wait ${cooldownRemaining} seconds before making another purchase.`);
        } else {
          setError(""); // Clear error if all checks pass
        }
      }

      setAmount(input);
      setAmountInWei(wei);
    } catch (err) {
      setError("Error converting input. Please check the value.");
      console.error("Conversion error:", err);
    }
  };

  const convertBigIntToFloat = useCallback((bigIntValue, decimals = 18) => {
    const SCALE = JSBI.exponentiate(toBI(10), toBI(decimals));
    return Number(bigIntValue.toString()) / Number(SCALE.toString());
  }, [JSBI]);

  const fetchContractData = useCallback(async () => {
    if (contract) {
      try {
        const rate = await contract.methods.rate().call();
        const cap = await contract.methods.cap().call();
        const tokensSold = await contract.methods.tokensSold().call();
        const deadline = await contract.methods.deadline().call();
        const start = await contract.methods.startTime().call();
        const lastPurchase = await contract.methods.lastPurchaseTime(account).call();
        const cooldown = await contract.methods.cooldownPeriod().call();
        const purchaseAmounts = await contract.methods.getTokensPurchasedByAddress(account).call();
        const maxPerPurchase = await contract.methods.maxPerPurchase().call();
        const maxPerAccount = await contract.methods.maxPerAccount().call();
        const buyers = await contract.methods.getBuyersAndAmounts().call()

        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

        // Use JSBI for time calculations
        const deadlineRemaining = checkRemainingTime(currentTime, deadline);
        const startRemaining = checkRemainingTime(currentTime, start);
        const kiddoHeld = await kiddoSC.methods.balanceOf(account).call();
        setKiddoHeld(convertBigIntToFloat(kiddoHeld));
        setMaxPerAccount(maxPerAccount);
        setMaxPerPurchase(maxPerPurchase);
        setPurchased(purchaseAmounts);
        setCooldown(cooldown);
        setLastPurchase(lastPurchase);
        setRate(Number(rate));
        setCap(cap);
        setTokensSold(tokensSold);
        setBuyers(buyers[0].length);
        setDeadline(
          JSBI.greaterThan(deadlineRemaining, JSBI.BigInt(0))
            ? new Date(Number(deadline) * 1000).toLocaleString()
            : "Finished"
        );

        setMaxShido(calculateShidoForMaxPurchase(maxPerPurchase, rate));
        setStart(
          JSBI.lessThanOrEqual(startRemaining, JSBI.BigInt(0))
            ? "Started"
            : new Date(Number(start) * 1000).toLocaleString()
        );
      } catch (error) {
        console.error("Error fetching contract data:", error);
      }
    }
  }, [contract, account, JSBI, checkRemainingTime, convertBigIntToFloat, kiddoSC]);

  useEffect(() => {
    window.gtag('config', 'G-9C7H9X1YYD', {
      page_path: location.pathname + location.search,
    });
  }, [location]);

  const trackRefresh = () => {
    trackEvent('Refresh', 'Click', 'User refreshed the data');
  };
  const trackBuy = (amount, outputTokens) => {
    trackEvent('Purchase', 'Click', `User bought ${outputTokens} tokens for ${amount} SHIDO`);
  };


  const getAccountAndBalance = useCallback(async () => {
    const web3 = new Web3(window.ethereum);
    const accounts = await web3.eth.getAccounts();
    const balance = await web3.eth.getBalance(accounts[0]);
    setBalance({ amount: convertBigIntToFloat(balance).toFixed(0), symbol: "SHIDO" });
    setAccount(accounts[0]);
  }, [convertBigIntToFloat]);

  const refresher = useCallback(async () => {
    trackRefresh();
    await fetchContractData();
    await getAccountAndBalance();
  }, [fetchContractData, getAccountAndBalance]);

  const connectWallet = async () => {
    try {
      if (!window.ethereum) return;

      const web3 = new Web3(window.ethereum);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const chainId = await web3.eth.getChainId();
      const expectedChainId = 9008; // Replace this with your target chain ID (e.g., 1 for Ethereum Mainnet)

      if (Number(chainId) !== expectedChainId) {
        alert(`Please switch to the correct network (SHIDO Mainnet). \n\nExpected Chain ID: ${expectedChainId}, but connected to: ${chainId}.`);
        return;
      }
      await getAccountAndBalance();
      const contractInstance = new web3.eth.Contract(ABI, CONTRACT_ADDRESS);
      const tokenContractInstance = new web3.eth.Contract(TOKEN_ABI, TOKEN_CONTRACT_ADDRESS);
      setKiddoSC(tokenContractInstance);
      setContract(contractInstance);
    } catch (error) {
      console.error("Error connecting wallet:", error);
    }
  };

  const buyTokens = async () => {
    if (contract && amount) {
      try {
        const amountInWei = Web3.utils.toWei(amount, "ether");
        await contract.methods.buyTokens().send({
          from: account,
          value: amountInWei,
        });
        trackBuy(amount, amountInWei);
        fetchContractData();
      } catch (error) {
        console.error("Error buying tokens:", error);
      }
    } else {
      console.error("Failed to buy tokens!");
    }
  };

  const handleLogout = () => {
    setAccount("");
  };


  // Withdraw Funds
  const withdrawFunds = async () => {
    if (contract) {
      try {
        await contract.methods.withdrawFunds().send({
          from: account
        });

      } catch (error) {
        console.error(error);
      }
    }
  };

  // Finalize Presale
  const finalizePresale = async () => {
    if (contract) {
      try {
        await contract.methods.finalizeSeedRound().send({
          from: account
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const isOwner = account && owner && account.toLowerCase() === owner;
  const checkCooldown = useCallback(async () => {
    if (contract && account && lastPurchase) {
      const currentTime = Math.floor(Date.now() / 1000); // Current time as a Number

      // Use JSBI for safe calculations
      const cooldownRemaining = checkRemainingTime(currentTime, lastPurchase, cooldown);

      // Convert the result to Number when setting state
      setUserCooldown(Number(cooldownRemaining.toString()));
    }
  }, [contract, account, lastPurchase, cooldown, checkRemainingTime]);



  useEffect(() => {
    fetchContractData();
    fetchOwner();
  }, [contract, fetchContractData, fetchOwner]);



  useEffect(() => {
    const timer = setInterval(() => {
      checkCooldown();
    }, 1000); // Update cooldown every second

    return () => clearInterval(timer); // Cleanup
  }, [checkCooldown]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length === 0) {
          handleLogout(); // No accounts available, treat as logged out
        } else {
          setAccount(accounts[0]); // Update to the new account
        }
      });
    }
  }, []);
  return (
    <div className="presale">
      <Navbar handleLogout={handleLogout} />
      <h1 className="title">KIDDO Seed-Round</h1>
      {!account ? (
        <button className="login-btn" onClick={connectWallet}>Connect Wallet</button>
      ) : (
        <div className="main">
          <p>Connected as: {account}</p>
          <button className="refresher" onClick={refresher}>Refresh</button>
          <div className="sale-info">
            <p><b>Starts</b>: {start}</p>
            <p><b>Deadline</b>: {deadline}</p>
            <p><b>Cap</b>: {convertBigIntToFloat(cap)} tokens</p>
          </div>
          <div className="progress" >
            <ProgressBar percentage={(convertBigIntToFloat(tokensSold) * 100) / convertBigIntToFloat(cap)} />
          </div>
          <div className="sale-info2">
            <p><b>Rate</b>: {createTokenWidget(rate)} per SHIDO</p>
            <p><b>Max Per Account</b>: {createTokenWidget(convertBigIntToFloat(maxPerAccount))}</p>
            <p><b>Max Per Purchase</b>: {createTokenWidget(convertBigIntToFloat(maxPerPurchase))}</p>

          </div>

          <div className="presale-box">
            <div className="container" id="info">
              <p><b>Buyers</b>: {buyers}</p>
              <p><b>Shido Raised</b>: {(convertBigIntToFloat(tokensSold) / rate).toFixed(0)}</p>
              <p><b>Shido for Goal</b>: {(convertBigIntToFloat(cap - tokensSold) / rate).toFixed(0)}</p>


              <p><b>Your purchases</b>: {createTokenWidget(convertBigIntToFloat(purchased))}</p>



              {userCooldown > 0 && <p style={{ color: "orange" }}>Cooldown: {formatCooldown(userCooldown)}</p>}


            </div>
            <div className="container" id="buy">
              <h3 className="buy-h">Buy KIDDO</h3>
              <p><b>Your KIDDO</b><span
                style={{ paddingLeft: "10px" }}
                onClick={() => setAmount(balance.amount)}
              >
                {kiddoHeld}
              </span></p>
              <p><b>Your SHIDO</b>:
                {/*<span
                  style={{ cursor: 'pointer', color: 'black', textDecoration: 'underline', paddingLeft: "10px" }}
                  onClick={() => setAmount(balance.amount)}
                >*/}
                {balance.amount}
                {/*</span>*/}

              </p>
              <p>Max Shido/purchase: {maxShido}</p>
              <input
                type="text"
                placeholder="Amount in SHIDO"
                value={amount}
                onChange={handleAmountChange}
              />
              {amount && <p style={{ color: "green" }}>Output: {amount * rate}</p>}

              {error && <p style={{ color: "red" }}>{error}</p>}
              {!error && start === "Started" && (
                <button
                  className="buy"
                  onClick={buyTokens}
                  disabled={!amountInWei || error}
                >
                  Buy KIDDO
                </button>
              )}

            </div>
          </div>

          {isOwner && (
            <div>
              <h3>Owner Functions</h3>
              <button onClick={withdrawFunds}>Withdraw Funds</button>
              <button onClick={finalizePresale}>Finalize Presale</button>
            </div>
          )}
        </div>

      )
      }
    </div >
  );
}

export default PreSale;
