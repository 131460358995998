import React from 'react';
import logoUrl from "../kiddo.png";
/**
 * Creates a React widget displaying a balance with a token logo.
 * @param {number} balance - The balance to display.
 * @param {string} logoUrl - The URL of the token logo image.
 * @returns {JSX.Element} A React component for the widget.
 */
export function createTokenWidget(balance) {
    // Ensure balance is a number and format it nicely
    const formattedBalance = typeof balance === 'number' ? balance.toFixed(0) : '0.00';

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                fontFamily: 'Arial, sans-serif',
            }}
        >
            <span>{formattedBalance}</span>
            <img
                src={logoUrl}
                alt="Token Logo"
                style={{
                    width: '32px',
                    height: '32px',
                    marginLeft: '8px',
                }}
            />
        </div>
    );
}

// Example Usage (to be used in a React app):
// const Widget = createTokenWidget(123.45, 'https://example.com/logo.png');
// Render <Widget /> in your app.