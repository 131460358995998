
const ProgressBar = ({ percentage }) => {
    const clampedPercentage = Math.min(100, Math.max(0, percentage));

    // Define colors based on thresholds
    let backgroundColor;
    if (clampedPercentage < 20) {
        backgroundColor = '#ff0000'; // Red
    } else if (clampedPercentage < 50) {
        backgroundColor = '#ff9900'; // Orange
    } else if (clampedPercentage < 80) {
        backgroundColor = '#ffff00'; // Yellow
    } else {
        backgroundColor = '#00ff00'; // Green
    }

    return (
        <div style={
            {
                width: "100%",
                backgroundColor: "#ddf",
                borderRadius: "8px",
                overflow: "hidden",
                margin: "10px 0"
            }
        }>
            <div
                style={{
                    width: `${percentage}%`,
                    backgroundColor: backgroundColor,
                    textAlign: "center",
                    color: "white",
                    padding: "5px 0",
                }}
            >
                {percentage.toFixed(2)}%
            </div>
        </div>
    );
};

export default ProgressBar;