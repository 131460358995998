const formatCooldown = (seconds) => {
    if (seconds <= 0) return "0 sec"; // Handle edge case for no time left

    const days = Math.floor(seconds / (24 * 3600));
    const hours = Math.floor((seconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    if (seconds < 3600) {
        // Show only minutes and seconds if less than an hour
        return `${minutes} min and ${secs} sec`;
    }

    let result = "";
    if (days > 0) result += `${days} d, `;
    if (hours > 0) result += `${hours} h, `;
    result += `${minutes} min and ${secs} sec`;

    return result;
};

export default formatCooldown;