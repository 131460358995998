import React from "react";

const TextWithImage = ({ imgSrc, text, imgSize = 16, paddingLeft = 30, paddingRight = 0 }) => {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            textAlign: "center",
            paddingLeft: `${paddingLeft}%`,
            paddingRight: `${paddingRight}%`,
            lineHeight: "2rem",
        }}>
            <img
                src={imgSrc}
                alt="icon"
                style={{ width: `${imgSize}px`, height: `${imgSize}px` }}
            />
            <p>{text}</p>
        </div>
    );
};

export default TextWithImage;
