import React, { useState, useEffect } from "react";
//import Web3 from "web3";
//import { ABI } from "./abi.js";
import "../css/home.css"
import Navbar from "../widgets/NavBar"
import TextWithImage from "../funcs/myCheckbox";
//import Check from "./check.png"
//import Ip from "./ip.png"
//import Vip from "./vip.png"
//import Work from "./work.png"
import SK from "./shidokid.webp"
//import { Link } from "react-router-dom";
import { trackEvent } from '../funcs/analytics.js';
import { useLocation } from 'react-router-dom';

const text = (
    "We are free kids and Shido Builders that are bound to use our knowledge to create bridges across Shido.\n\n\n\n"
    + "We are creating our own Smart Contract toolkit, and whatever serves us, may serve you too, creative one!"
    + "\n\n\n\nFollow along through our journey and be part of our history!"
);

function Home() {
    const location = useLocation();
    const [imgSize, setImgSize] = useState(420);

    useEffect(() => {
        window.gtag('config', 'G-9C7H9X1YYD', {
            page_path: location.pathname + location.search,
        });
    }, [location]);

    const handleLinkClick = (platform) => {
        trackEvent(
            "link_click",              // Event name
            "Social Links",            // Event category
            `Visited ${platform}`,     // Event label
        );
    };

    useEffect(() => {
        const handleResize = () => {
            setImgSize(window.innerWidth <= 768 ? 200 : 420); // Smaller image size for mobile
        };

        handleResize(); // Set initial size
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <div className="home">
            <Navbar />
            <h1> Welcome to ShidoKid</h1>
            <TextWithImage
                imgSrc={SK}
                text={text}
                imgSize={imgSize}
                paddingLeft={5}
                paddingRight={5}
            />

            <h2>Check us out on:</h2>
            <div className="links">
                <a
                    className="home-link"
                    href="https://x.com/shidokid"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleLinkClick("X")}
                >
                    X
                </a>
                <a
                    className="home-link"
                    href="https://t.me/shido_kid"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleLinkClick("Telegram")}
                >
                    Telegram
                </a>
                <a
                    className="home-link"
                    href="https://medium.com/@shidokid.fi"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleLinkClick("Medium")}
                >
                    Medium
                </a>
                <a
                    className="home-link"
                    href="https://shidokid.gitbook.io/shidokid"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleLinkClick("GitBook")}
                >
                    GitBook
                </a>
            </div>
            {/* 
            <div className="roadmap">
                <h2>Roadmap</h2>
                <div className="alpha">
                    <h3>Alpha</h3>
                    <TextWithImage imgSrc={Check} text="New KIDDO Deploy" imgSize={16} />
                    <TextWithImage imgSrc={Check} text="Token Migration" imgSize={16} />
                    <TextWithImage imgSrc={Work} text="Seed Round" imgSize={24} />
                    <TextWithImage imgSrc={Vip} text="Pre Sale" imgSize={16} />
                    <TextWithImage imgSrc={Vip} text="Pool Creation" imgSize={16} />

                </div>
                <div className="beta">
                    <h3>Beta</h3>
                    <TextWithImage imgSrc={Vip} text="Vault Contract" imgSize={16} />
                    <TextWithImage imgSrc={Vip} text="Staking Contract" imgSize={16} />
                    <TextWithImage imgSrc={Vip} text="Game Contract" imgSize={16} />
                    <p></p>
                    <p></p>
                    <p></p>

                </div>

            </div>
            */}
        </div>
    );





}

export default Home;
